import React from 'react';
import Layout from 'components/Layout';
import { Image as LogoImage } from 'components/BigLogo';
import { StyledContent, HeroLandscape, HeroBackground, BlockBackground, BgVideo, LeftElements, RightElements, WomanSwing, CountdownStyled, TimerWrapper } from './styles';
import SSPresents from 'assets/images/SSPresents.png';
import MountainLeft from 'assets/images/mountain-left.png';
import MountainRight from 'assets/images/mountain-right.png';
import Trees from 'assets/images/pandanas.png';
import LeftLeg from 'assets/images/big-leg-front.png';
import RightLeg from 'assets/images/big-leg-back.png';
import Tree from 'assets/images/pandanas-tree.png';
import ManVan from 'assets/images/mushroom-left.png';
import MushroomRight from 'assets/images/mushroom-right.png';
import LadyLeaning from 'assets/images/lady-leaning.png';
import WomanLying from 'assets/images/woman-lying-down.png';
import WomanTea from 'assets/images/woman-tea.png';
import WomanYellow from 'assets/images/woman-in-yellow-shirt.png';
import WomanYellowPants from 'assets/images/WomanInYellowPants.png';
import WomanJeasRight from 'assets/images/JeansWomanBottomRight.png';
import WomanCamera from 'assets/gifs/WOMAN_with_Camera.gif';
import Sparkler from 'assets/gifs/Sparkler_and_Hand_2.gif';
import CoupleDancing from 'assets/gifs/mushroom_couple.gif';
import WomanSitting from 'assets/gifs/woman_sitting_on_mush.gif';
import DancingWomen from 'assets/gifs/green_and_yellow_women.gif';
import DancingGuy from 'assets/gifs/Dancing_Guy_in_Denim.gif';
import DancingWomanDress from 'assets/gifs/Dancing_Woman_Green_Dress.gif';
import TwoDancers from 'assets/gifs/Arms_up_and_Tiny_Guy_Dancers.gif';
import Image from 'components/Image';
import HeroBgVideo from 'assets/videos/hero_bg.mp4';
import Countdown from 'react-countdown';
import Cta from 'components/Cta';
const zeroPad = (num, places) => String(num).padStart(places, '0')

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <div style={{textAlign: 'center'}}>
      <Cta url={`https://www.sansar.com/`} title="ENTER THE SPLENDOUR XR WORLD" target={`_blank`} inverted={false} color="SITCBlue" style={{marginBottom: '16px'}}/>
    </div>;
  } else {
    // Render a countdown
    return <CountdownStyled><TimerWrapper>
      <div className="days time">{zeroPad(days, 2)}:</div>
      <div className="timehrs time">{zeroPad(hours, 2)}:</div>
      <div className="minutes time">{zeroPad(minutes, 2)}:</div>
      <div className="seconds time">{zeroPad(seconds, 2)}</div>
      </TimerWrapper></CountdownStyled>;
  }
};

const FrontpageHero: React.FunctionComponent<any> = ({ children, pageHasAnnouncement }) => (
  <Layout.Section>
    <StyledContent isFirstSection={true} pageHasAnnouncement={pageHasAnnouncement}>
      <Layout.ContentInner>
        <HeroBackground>
          <Image className="ss-tag" sourceUrl={SSPresents} />
          <LogoImage />
          <HeroLandscape>
            <Image className="mountain-left" sourceUrl={MountainLeft} />
            <Image className="mountain-right" sourceUrl={MountainRight} />
            <Image className="sparkler" sourceUrl={Sparkler} />
            <Image className="tree" sourceUrl={Tree} />
            <Image className="trees" sourceUrl={Trees} />
          </HeroLandscape>
          <Image className="bgLegLeft" sourceUrl={LeftLeg} />
          <Image className="bgLegRight" sourceUrl={RightLeg} />
          <BgVideo autoPlay={true} muted={true} controls={false} loop={true}>
            <source src={HeroBgVideo} type="video/mp4" />
          </BgVideo>
        </HeroBackground>
        <BlockBackground>
          <LeftElements>
            <Image className="mushroomLeft" sourceUrl={ManVan} />
            <Image className="coupleDancing" sourceUrl={CoupleDancing} />
            <Image className="womanSitting" sourceUrl={WomanSitting} />
            <Image className="womanLeaning" sourceUrl={LadyLeaning} />
          </LeftElements>
          <Image className="dancingGuy" sourceUrl={DancingGuy} />
          <Image className="dancingWomanDress" sourceUrl={DancingWomanDress} />
          <Image className="twoDancers" sourceUrl={TwoDancers} />
          <Image className="womanLying" sourceUrl={WomanLying} />
          <Image className="dancingWomen" sourceUrl={DancingWomen} />
          <Image className="womanYellow" sourceUrl={WomanYellow} />
          <RightElements>
            <Image className="mushroomRight" sourceUrl={MushroomRight} />
            <Image className="womanCamera" sourceUrl={WomanCamera} />
            <WomanSwing className="womanTea" sourceUrl={WomanTea} />
          </RightElements>
          {children}
          <Image className="womanYellowPantsLeft" sourceUrl={WomanYellowPants} />
          <Image className="womanJeasRight" sourceUrl={WomanJeasRight} />
        </BlockBackground>
      </Layout.ContentInner>
    </StyledContent>
  </Layout.Section>
);

export default FrontpageHero;
