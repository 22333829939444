import React from 'react';
import BlockPage from 'layout/components/BlockPage';
import BlockSection from 'sections/Block';
import FrontpageHeroSection from 'sections/FrontpageHero';
import FrontPageWrapper from './styles';
import HeroAd from 'components/HeroAd';
import { DFPManager } from 'react-dfp';
import { FooterAd } from 'components/FooterAd';
class FrontPage extends BlockPage {
  componentDidMount() {
    setTimeout(() => {
      DFPManager.load();
    }, 5000);
  }
  renderChildren() {
    const { content } = this.props;

    return (
      <FrontPageWrapper>
        {content &&
          content.flexibleContent &&
          content.flexibleContent.contentBlocks.map((contentBlock, index) => {
            if (index === 0) {
              return (
                <div key={`first-${index}`}>
                  <FrontpageHeroSection pageHasAnnouncement={this.pageHasAnnouncement()}>
                    {this.renderBlock(contentBlock)}
                  </FrontpageHeroSection>
                  <HeroAd />
                </div>
              );
            } else if (index >= content.flexibleContent.contentBlocks.length - 1) {
              return (
                <BlockSection key={`last-${index}`} onVisible={() => this.updateBackgroundForIndex(index)}>
                  {this.renderBlock(contentBlock)}
                </BlockSection>
              );
            }

            return (
              <BlockSection key={`block-${index}`} onVisible={() => this.updateBackgroundForIndex(index)}>
                {this.renderBlock(contentBlock)}
              </BlockSection>
            );
          })}
        <BlockSection style={{ textAlign: 'center' }}>
          <FooterAd />
        </BlockSection>
      </FrontPageWrapper>
    );
  }
}

export default FrontPage;
