import styled from 'styled-components';
import { ThemeType } from 'types';

const FrontPageWrapper = styled.div(
  ({ theme: { spacing } }: ThemeType) => `
    transform-style: preserve-3d;
 `,
);

export default FrontPageWrapper;
